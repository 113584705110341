<template>
  <div class="content-wrapper">
    <!-- Main content -->
    <section class="content">
      <section style="margin-bottom: 1%">
        <a-button type="primary" @click="goToAddProduct(false)" v-if="priv('f_oms_goods_create')">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('新建商品') }}
        </a-button>
        <a-button type="primary" @click="showModal('add')" v-if="priv('f_oms_goods_batch_create')">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('批量新建商品') }}
        </a-button>
        <a-button
          type="primary"
          @click="showModal('update')"
          v-if="priv('f_oms_goods_batch_update')"
        >
          <i class="icon iconfont icon-synchronize" style="margin-right: 5px"></i>
          {{ $t('批量更新商品') }}
        </a-button>
      </section>

      <a-row :gutter="16">
        <a-col :span="8">
          <a-input
            v-model.trim="formData.keyword"
            allowClear
            :placeholder="
              $t(`请输入`) + $t(`商品名称`) + '/' + $t(`商家编码`) + '/' + $t(`商品条码`)
            "
          />
        </a-col>
        <a-col :span="8">
          <CategoryList
            ref="categoryList"
            :canNotAdd="true"
            @handleCategoryChange="changeCategory"
          />
        </a-col>
        <!-- <a-col :span="5">
          <a-select
            allowClear
            show-search
            style="width: 100%"
            v-model="formData.brandId"
            :placeholder="$t(`请输入`) + '/' + $t(`选择`) + $t(`品牌`)"
          >
            <a-select-option
              v-for="item in brandNameOptions"
              :value="item.brandId"
              :key="item.brandName"
            >
              {{ item.brandName }}
            </a-select-option>
          </a-select>
        </a-col> -->
        <a-col :span="8">
          <a-button @click="queryOmsGoodsList(1)" class="btnBgc" v-if="priv('f_oms_goods_list')">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <i class="iconfont icon-synchronize" style="margin-right: 5px"></i>
            {{ $t(`重置`) }}
          </a-button>
          <a-button type="submit" @click="exportOmsGoods">
            <i class="iconfont icon-upload" style="margin-right: 5px"></i>
            {{ $t(`导出`) }}
          </a-button>
        </a-col>
      </a-row>

      <div class="table-content">
        <div class="table-header">
          <div class="row status-list-wrap">
            <input type="hidden" v-model="formData.status" />
            <!-- <ul class="status-list-top clearfix">
              <li
                v-for="status in statusListTop"
                :class="{ active: status.status === formData.status }"
                @click="setSearchStatus(status.status)"
                :key="status.status"
              >
                <span>{{ status.statusName }}</span>
              </li>
            </ul> -->
            <a-tabs default-active-key="" @change="setSearchStatus">
              <a-tab-pane key="">
                <template slot="tab">
                  <span>{{ $t('全部') }}</span>
                </template>
              </a-tab-pane>
              <a-tab-pane key="open">
                <template slot="tab">
                  <span>{{ $t('已启用') }}</span>
                </template>
              </a-tab-pane>
              <a-tab-pane key="close">
                <template slot="tab">
                  <span>{{ $t('未启用') }}</span>
                </template>
              </a-tab-pane>
            </a-tabs>
          </div>
          <ul class="action-list" v-show="tableData.length">
            <li v-if="priv('f_oms_goods_batch_open')">
              <!-- 批量启用 -->
              <a-button class="batchBgc" @click="batchEnable">
                {{ $t(`批量启用`) }}
              </a-button>
            </li>
            <li v-if="priv('f_oms_goods_batch_close')">
              <!-- 批量停用 -->
              <a-button class="batchBgc" style="margin-left: 12%" @click="batchDisenable">
                {{ $t(`批量停用`) }}
              </a-button>
            </li>
            <!-- 批量发布至仓库 -->
            <!-- <li v-if="priv('f_oms_goods_publish')">
              <a-button class="batchBgc" style="margin-left: 24%" @click="showWarehouseModal">
                {{ $t(`批量发布`) }}
              </a-button>
            </li> -->
            <!-- 批量同步仓库属性 -->
            <!-- <li>
              <a-button class="batchBgc" style="margin-left: 22%" @click="showWarehouseModal('')">
                {{ $t(`批量同步仓库属性`) }}
              </a-button>
            </li> -->
          </ul>
        </div>
        <div class="table-body">
          <a-table
            :columns="tableColumns"
            :data-source="tableData"
            rowKey="skuId"
            :pagination="false"
            :scroll="{ x: 1500 }"
          >
            <template slot="indexTitle">
              <a-checkbox
                :indeterminate="indeterminate"
                :checked="checkAll"
                @change="selectRowAll"
              ></a-checkbox>
            </template>
            <div slot="action" class="flex-btns" slot-scope="text, scope">
              <a-switch
                v-if="scope.status !== 'delete'"
                style="margin-right: 10px"
                size="small"
                :checked="scope.sku_status"
                @change="onSwitchChange(scope)"
              />
              <span v-else class="red">{{ $t('已删除') }}</span>
              <!-- <span class="t-btn" @click="goToLog(scope)">{{
                $t("查看操作日志")
              }}</span> -->
            </div>
            <div slot="action1" class="flex-btns" slot-scope="text, scope">
              <template v-if="scope.goodsType === 'GENERAL' && needProcess(scope)">
                <div class="t-btn" @click="uploadIngredients(scope)">
                  {{ scope.properties ? $t('查看配料表') : $t('上传配料表') }}
                </div>
                <div class="t-btn" v-if="scope.properties" @click="downloadIngredients(scope)">
                  {{ $t('打印配料表') }}
                </div>
              </template>
            </div>
            <template slot="goodsName" slot-scope="text, record">
              <div class="goods-name">{{ text }}</div>
              <div class="goods-main-image" v-if="record.images?.length">
                <img :src="record.images[0]" class="main-image" />
                <div class="image-list flex-ct">
                  <ImagePreview
                    :urls="record.images"
                    imgStyle="width: 50px; height: 50px;"
                    style="flex-wrap: nowrap"
                  />
                </div>
              </div>
            </template>
            <div slot="skuCode" slot-scope="text, record">
              <a-popover v-if="record.skuImageList.length > 0">
                <template slot="content">
                  <div style="width: 180px; min-height: 180px">
                    <img :src="record.skuImageList[0].url" alt="" style="width: 180px" />
                  </div>
                </template>
                <a>{{ text }}</a>
              </a-popover>
              <a v-else>{{ text }}</a>
            </div>
          </a-table>
        </div>
        <div class="table-footer">
          <pagination
            :pageNo="pageNo"
            :length="pageSize"
            :total-count="total"
            @paginate="paginate"
          ></pagination>
        </div>
      </div>
    </section>
    <a-modal v-model="visible" :title="modalInfo.modalTitle" :width="500" :footer="null">
      <div>
        <p class="mb10" v-if="modalInfo.type === 'update'">
          {{ $t('注:导入更新时,有值则默认覆盖,字段为空则默认不更新！！') }}
        </p>
        <a-upload-dragger
          name="file"
          :multiple="true"
          :action="modalInfo.modalUrl"
          :headers="{ Authorization: token }"
          :data="{
            trade: $store.state.userSession?.trade,
          }"
          @change="handleChange"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="cloud-upload" />
          </p>
          <p class="ant-upload-hint">{{ $t('点击选择或者拖拽要上传的文件') }}</p>
        </a-upload-dragger>
        <div class="template-download">
          <div class="infoTitle mt20">
            <span class="iconLeft"></span>
            {{ $t('模板下载') }}
          </div>
          <a target="_blank" class="text-info mt10" :href="modalInfo.xlsDownloadUrl">
            {{ modalInfo.modalTitle }}{{ $t('模板') }}.xls
          </a>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="uploadVisible" :title="$t('发布视频')" :width="500" @ok="uploadVideo">
      <p>
        <span style="color: red">*</span>
        {{ $t('店铺') }}
      </p>
      <SelectShop :code.sync="uploadShopCode" @selected="selectedShop" />
    </a-modal>
    <PublishGoodsModal
      :goodsId="modalGoodsId"
      :modalSkuId="modalSkuId"
      :publishVisible.sync="publishVisible"
      @publishGoods="publishGoods"
    />
    <PropertiesModal ref="propertiesModal" :skuInfo="currentSku" @success="queryOmsGoodsList" />
    <Printinfo ref="printinfo" :currentSku="currentSku" />

    <a-modal v-model="resultModalVisible" :title="$t('执行结果')" :footer="null">
      <div class="modal-block" v-if="Object.keys(resultInfo.successMap).length > 0">
        <div class="block-title">{{ $t('成功列表') }}</div>
        <div class="block-body">
          <div class="result-item mt6" v-for="(item, key) in resultInfo.successMap" :key="key">
            <div class="shop-name">{{ $t('商家编码') }}：{{ key }}</div>
          </div>
        </div>
      </div>
      <div class="modal-block mt10" v-if="Object.keys(resultInfo.failedReasonMap).length > 0">
        <div class="block-title">{{ $t('失败列表') }}</div>
        <div class="block-body">
          <div class="result-item mt6" v-for="(item, key) in resultInfo.failedReasonMap" :key="key">
            <div class="code">{{ item }}</div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { Modal } from 'ant-design-vue'
import { mapState, mapMutations } from 'vuex'
import http from '@service/axios'
import api from '@service/api'
import { getCookie } from '@/common/cookie'
import pagination from '@component/pagination'
import PublishGoodsModal from '@component/publishGoodsModal'
import SelectShop from '@component/selectShop'
import PropertiesModal from '@component/propertiesModal'
import Printinfo from '@component/Printinfo'
import CategoryList from '@component/categoryList'
import { customCell } from '@/common'
import ImagePreview from '@component/ImagePreview'

export default {
  name: 'products',
  mounted() {
    this.queryGoodsTagList()
    this.queryOmsGoodsList()
    this.getbrandList()
  },
  activated() {
    let refresh = localStorage.getItem('refresh')
    if (refresh) {
      localStorage.removeItem('refresh')
      this.queryOmsGoodsList()
    }
  },
  components: {
    pagination,
    PublishGoodsModal,
    SelectShop,
    PropertiesModal,
    Printinfo,
    CategoryList,
    ImagePreview,
  },
  data() {
    return {
      bodyStyle: {
        maxHeight: '300px',
        overflowY: 'scroll',
      },
      status: '',
      formData: {
        keyword: undefined,
        status: undefined,
        brandId: undefined,
        categoryId: undefined,
        categoryName: undefined,
        tag: undefined,
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      selectedRowKeys: [],
      selectedGoodsKeys: [],
      tableData: [],
      modalInfo: {
        modalTitle: '',
        modalUrl: '',
        xlsRequestUrl: '',
        xlsDownloadUrl: '', //模板下载链接
      },
      visible: false,
      publishVisible: false,
      modalGoodsId: '',
      modalSkuId: [],
      tagList: [],
      brandNameOptions: [],
      wareHouseVisible: false,
      warehouseShipper: null,
      mode: 'multiple',
      indeterminate: false,
      publicType: 1,
      uploadVisible: false,
      uploadShopCode: undefined,
      uploadShopPlatform: undefined,
      modalType: 'single',

      currentSku: {},
      resultModalVisible: false,
      resultInfo: {
        successMap: {},
        failedReasonMap: {},
      },
    }
  },
  computed: {
    ...mapState(['goodsTypeMapping']),
    checkAll() {
      let bool = true
      this.tableData.forEach((item) => {
        if (!this.selectedRowKeys.includes(item.skuId)) {
          bool = false
        }
      })
      return bool
    },
    tableColumns() {
      const vm = this
      let list = [
        {
          key: 'index',
          width: 60,
          align: 'center',
          slots: { title: 'indexTitle' },
          customRender: (text, record, index) => {
            let child = (
              <a-checkbox
                checked={this.selectedRowKeys.includes(record.skuId)}
                onChange={() => this.selectRow(record)}
              ></a-checkbox>
            )
            const obj = {
              children: child,
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(
              record['goodsCode'],
              this.tableData,
              'goodsCode',
              index
            )
            return obj
          },
        },
        {
          dataIndex: 'goodsName',
          title: this.$t('商品名称'),
          width: 200,
          scopedSlots: { customRender: 'goodsName' },
          customCell: (record, index) => customCell(record, index, this.tableData, 'goodsCode'),
        },
        {
          title: this.$t('商家编码'),
          dataIndex: 'skuCode',
          key: 'skuCode',
          scopedSlots: { customRender: 'skuCode' },
        },
        {
          title: this.$t('商品条码'),
          key: 'barcode',
          dataIndex: 'barcode',
        },
        {
          title: this.$t('商品类型'),
          key: 'goodsType',
          dataIndex: 'goodsType',
          customRender: (text, record) => {
            let str = this.goodsTypeMapping[text]
            if (this.needProcess(record)) {
              str += `(${this.$t('需加工')})`
            }
            return str
          },
        },
        {
          title: this.$t('规格值'),
          key: 'styleDesc',
          dataIndex: 'styleDesc',
        },
        {
          title: this.$t('创建时间'),
          key: 'createdAt',
          dataIndex: 'createdAt',
        },
        {
          title: this.$t('状态'),
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 80,
        },
        {
          title: this.$t('sku操作'),
          key: 'action1',
          scopedSlots: { customRender: 'action1' },
          width: 120,
        },
        {
          title: this.$t('商品操作'),
          key: 'action2',
          width: 200,
          fixed: 'right',
          customRender: (text, record, index) => {
            var child = vm.$createElement(
              'div',
              {
                class: {
                  'flex-btns': true,
                  flex: true,
                },
              },
              [
                vm.$createElement('span', {
                  class: {
                    't-btn': true,
                  },
                  domProps: {
                    innerHTML: this.$t('复制'),
                  },
                  on: {
                    click() {
                      vm.copyGoods(record)
                    },
                  },
                }),
                vm.priv('f_oms_goods_detail')
                  ? vm.$createElement('span', {
                      class: {
                        't-btn': true,
                      },
                      domProps: {
                        innerHTML: this.$t('查看详情'),
                      },
                      on: {
                        click() {
                          vm.goToDetail(record, false)
                        },
                      },
                    })
                  : null,
                vm.priv('f_oms_goods_update')
                  ? vm.$createElement('span', {
                      class: {
                        't-btn': true,
                      },
                      domProps: {
                        innerHTML: this.$t('编辑'),
                      },
                      on: {
                        click() {
                          vm.goToDetail(record, true)
                        },
                      },
                    })
                  : null,
              ]
            )
            const obj = {
              children: child,
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(
              record['goodsCode'],
              this.tableData,
              'goodsCode',
              index
            )
            return obj
          },
        },
      ]
      return list
    },
    statusListTop() {
      return [
        {
          statusName: this.$t(`全部`), //全部
          status: '',
        },
        {
          statusName: this.$t(`已启用`), //"已启用",
          status: 'open',
        },
        {
          statusName: this.$t(`未启用`), //"未激活",
          status: 'close',
        },
        {
          statusName: this.$t(`已删除`), //已删除,
          status: 'delete',
        },
      ]
    },
    token() {
      return getCookie('d_session_id')
    },
    tableHeight() {
      return (window.innerHeight - 430) * this.$store.state.rate
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    selectedShop(data) {
      this.uploadShopCode = data.code
      this.uploadShopPlatform = data.platform
    },
    reset() {
      for (let item in this.formData) {
        this.formData[item] = ''
      }
      this.$refs.categoryList.clear()
      this.pageNo = 1
      this.pageSize = 10
      this.queryOmsGoodsList(1)
    },
    showUploadVideo(text) {
      this.uploadGoodsId = text.goodsId
      this.uploadVisible = true
    },
    uploadVideo() {
      let data = {
        goodsId: this.uploadGoodsId,
        shopCode: this.uploadShopCode,
        platform: this.uploadShopPlatform,
      }
      http({
        instance: this,
        url: api.uploadVideoToPlatform,
        type: 'post',
        data: data,
        hasLoading: false,
        success() {
          this.$success(this.$t('发布成功'))
        },
      })
    },
    selectRowAll() {
      this.selectedGoodsKeys = this.checkAll
        ? []
        : Array.from(new Set(this.tableData.map((item) => item.goodsId)))
      this.selectedRowKeys = this.checkAll ? [] : this.tableData.map((item) => item.skuId)
      this.indeterminate = false
    },
    selectRow(record) {
      let arr = this.tableData
        .filter((item) => item.goodsCode == record.goodsCode)
        .map((item) => item.skuId)

      while (arr.length) {
        let skuId = arr.shift()
        let idx = this.selectedRowKeys.indexOf(skuId)
        if (idx > -1) {
          this.selectedRowKeys.splice(idx, 1)
        } else {
          this.selectedRowKeys.push(skuId)
        }
      }
      let index = this.selectedGoodsKeys.indexOf(record.goodsId)
      if (index > -1) {
        this.selectedGoodsKeys.splice(index, 1)
      } else {
        this.selectedGoodsKeys.push(record.goodsId)
      }
      this.indeterminate =
        !!this.selectedRowKeys.length && this.selectedRowKeys.length < this.tableData.length
    },
    showWarehouseModal() {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning(this.$t('至少选择一项'))
      }
      this.modalType = 'multiple'
      this.modalSkuId = this.selectedRowKeys
      this.modalGoodsId = this.selectedGoodsKeys
      this.$nextTick(() => {
        this.publishVisible = true
      })
    },
    selectedWarehouseShipper(val) {
      this.warehouseShipper = val
    },
    handlerClick() {
      const _self = this
      let data = null
      if (this.mode == 'multiple') {
        if (this.publicType == 1) {
          data = {
            skuIds: _self.selectedRowKeys,
            shipperList: _self.warehouseShipper.shipperList,
          }
          http({
            instance: _self,
            url: api.batchCreateWmsGoods,
            type: 'post',
            data: data,
            hasLoading: false,
            success() {
              _self.$success(_self.$t('发布成功'))
              _self.wareHouseVisible = false
              _self.selectedRowKeys = []
            },
          })
        } else {
          data = {
            goodsIds: _self.selectedGoodsKeys,
          }
          http({
            instance: _self,
            url: api.syncGoodsToScm,
            type: 'post',
            data: data,
            hasLoading: false,
            success() {
              _self.$success(_self.$t('发布成功'))
              _self.wareHouseVisible = false
              _self.selectedRowKeys = []
              _self.selectedGoodsKeys = []
            },
          })
        }
      } else {
        data = {
          skuIds: _self.selectedRowKeys,
          shipper: _self.warehouseShipper.shipperList,
        }
        http({
          instance: _self,
          url: api.updateSkuAttributeByWmsGoods,
          type: 'post',
          data: data,
          hasLoading: false,
          success() {
            _self.$success(_self.$t('发布成功'))
            _self.wareHouseVisible = false
            _self.selectedRowKeys = []
          },
        })
      }
    },
    getbrandList() {
      http({
        instance: this,
        type: 'post',
        url: api.brandList,
        hasLoading: true,
        data: {},
        success: (res) => {
          this.brandNameOptions = res.result.brandList
        },
        error: () => {},
      })
    },
    queryGoodsTagList() {
      http({
        instance: this,
        type: 'post',
        url: api.queryGoodsTagList,
        success: (res) => {
          this.tagList = res.result.tagList
        },
      })
    },
    goToLog(item) {
      let obj = {
        name: 'productOperationLog',
        view: 'productOperationLog',
        query: { id: item.skuCode },
      }
      this.addPane(obj)
      this.$router.push(obj)
    },
    showModal(type) {
      let language_mapping = {
        zh: 'zh_CN',
        en: 'en_US',
        th: 'th_TH',
      }
      let cpid = this.$store.getters.partyId
      let path = `?language=${language_mapping[this.$i18n.locale]}&cpid=${cpid}`
      let mapping = {
        add: {
          type: 'add',
          modalTitle: this.$t('新建商品'),
          modalUrl: api.batchCreateOmsGoods + path,
          xlsRequestUrl: api.getBatchCreateGoodsExcel,
          xlsDownloadUrl:
            'https://geez-prod.oss-cn-shanghai.aliyuncs.com/template/physicalShop/%E5%95%86%E5%93%81%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
        },
        update: {
          type: 'update',
          modalTitle: this.$t('批量更新商品'),
          modalUrl: api.batchUpdateOmsGoods + path,
          xlsDownloadUrl:
            'https://geez-prod.oss-cn-shanghai.aliyuncs.com/template/goods/%E5%95%86%E5%93%81%E6%89%B9%E9%87%8F%E6%9B%B4%E6%96%B0%E6%A8%A1%E6%9D%BF240702.xlsx',
        },
      }
      this.modalInfo = mapping[type]
      if (this.$store.state.userSession?.trade === 'RENT') {
        this.modalInfo.xlsDownloadUrl =
          'https://geez-prod.oss-cn-shanghai.aliyuncs.com/template/goods/rent%20%E5%95%86%E5%93%81%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls'
      }
      this.$nextTick(() => {
        this.visible = true
        this.$refs.categoryList.clear()
      })
    },
    syncLazadaGoods() {
      http({
        instance: this,
        type: 'post',
        url: api.syncLazadaGoods,
        hasLoading: true,
        data: {},
        success: () => {
          this.$success(this.$t('同步成功'))
        },
      })
    },
    changeCategory(data) {
      if (data) {
        this.formData.categoryId = data.value
        this.formData.categoryName = data.label
      } else {
        this.formData.categoryId = undefined
        this.formData.categoryName = undefined
      }
    },
    exportOmsGoods() {
      let currentShop = this.$store.state.currentShop
      let merchantCode = this.$store.state.userSession?.merchantCode,
        buId = this.$store.getters.partyId,
        platform = currentShop?.platform
      http({
        instance: this,
        url: api.exportOMSOrder,
        data: {
          bookCode: 'ExportOmsGoods',
          mergeCode: currentShop?.uniqueCode,
          merchantShopName: currentShop?.name,
          appCode: 'OMS',
          params: {
            keyword: this.formData.keyword,
            categoryId: this.formData.categoryId,
            categoryName: this.formData.categoryName,
            brandId: this.formData.brandId,
            merchantCode,
            buId,
            platform,
            goodsIds: this.selectedGoodsKeys,
            status: this.formData.status,
          },
        },
        type: 'post',
        hasLoading: false,
        success: (res) => {
          console.log(res)
          if (res.result.task_id) {
            this.$message.success(this.$t('创建导出任务成功,请前往【数据中心/下载中心】下载'))
          } else {
            this.$message.error(this.$t('创建导出任务失败'))
          }
        },
      })
    },
    queryOmsGoodsList(first_page = false) {
      first_page && (this.pageNo = 1)
      this.$nextTick(async () => {
        let data = {
          keyword: this.formData.keyword,
          categoryId: this.formData.categoryId,
          categoryName: this.formData.categoryName,
          brandId: this.formData.brandId,
          pageNo: this.pageNo,
          platform: this.$store.state.currentShop.platform,
          pageSize: this.pageSize,
        }
        if (this.formData.status !== -1) {
          data.status = this.formData.status
        }
        if (this.formData.tag) {
          data.tag = this.formData.tag
        }
        let res = await http({
          instance: this,
          type: 'post',
          url: api.omsGoodsList,
          hasLoading: true,
          data,
        })
        let skuList = [...res.result.skuList]
        //TODO sku_status没有返回值 自己造
        skuList.forEach((item) => {
          if (item.status === 'open') {
            this.$set(item, 'sku_status', true)
          } else {
            this.$set(item, 'sku_status', false)
          }
        })
        this.tableData = skuList
        this.total = res.result.total
      })
    },
    goToAddProduct(quickCreate) {
      let obj = {
        name: 'productsDetail',
        view: 'productsDetail',
        query: { type: 'add', quickCreate },
      }
      localStorage.setItem('from_create', 1)
      this.addPane(obj)
      this.$router.push(obj)
    },
    goToDetail(record, is_edit) {
      let obj = {
        name: 'productsDetail',
        view: 'productsDetail',
        query: { id: record.goodsId, type: 'edit', is_edit },
      }
      this.addPane(obj)
      this.$router.push(obj)
    },
    copyGoods(record) {
      let obj = {
        name: 'productsDetail',
        view: 'productsDetail',
        query: { id: record.goodsId, type: 'copy' },
      }
      this.addPane(obj)
      this.$router.push(obj)
    },
    setSearchStatus(status) {
      this.$set(this.formData, 'tag', '')
      this.$set(this.formData, 'status', status)
      this.queryOmsGoodsList()
    },
    setSearchTag(tag) {
      this.$set(this.formData, 'status', -1)
      this.$set(this.formData, 'tag', tag)
      this.queryOmsGoodsList()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    batchEnable() {
      this.batchHandle({
        modalAction: this.$t('启用'),
        url: api.openOmsGoods,
      })
    },
    batchDisenable() {
      this.batchHandle({
        modalAction: this.$t('停用'),
        url: api.closeOmsGoods,
      })
    },
    confirmSelect() {
      let is_empty = false
      if (this.selectedRowKeys.length === 0) {
        is_empty = true
        this.$warning(this.$t('至少选择一项'))
      }
      return is_empty
    },
    batchHandle({ modalAction, url }) {
      if (this.confirmSelect()) return
      Modal.confirm({
        // title: 'Confirm',
        content: `${this.$t('确认')}${modalAction}${this.$t('选中商品')}`,
        okText: this.$t('确认'),
        cancelText: this.$t('取消'),
        onOk: () => {
          let skuIdList = [...this.selectedRowKeys]
          this.commonHttp({
            url,
            modalAction,
            data: { skuIdList },
          }).then(() => {
            this.queryOmsGoodsList()
          })
        },
      })
    },
    commonHttp({ url, modalAction, data }) {
      return new Promise((resolve) => {
        http({
          instance: this,
          type: 'post',
          hasLoading: true,
          url,
          data,
          success: (res) => {
            let failedReasonMap = res.result.failedReasonMap || {}
            if (Object.keys(failedReasonMap).length > 0) {
              this.resultInfo = res.result
              this.resultModalVisible = true
            } else {
              this.$success(`${modalAction}${this.$t('成功')}`)
            }
            resolve(res)
          },
        })
      })
    },
    onSwitchChange(scope) {
      let checked = scope.sku_status
      let url = checked ? api.closeOmsGoods : api.openOmsGoods,
        modalAction = checked ? this.$t('停用') : this.$t('启用'),
        skuIdList = [scope.skuId]
      this.commonHttp({
        url,
        modalAction,
        data: { skuIdList },
      }).then(() => {
        scope.sku_status = !checked
      })
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.queryOmsGoodsList()
    },
    publishGoods(modalData) {
      let data = {
        ...modalData,
      }
      let url = api.publishGoods
      if (this.modalType === 'multiple') {
        url = api.batchPublishGoods
        delete data.goodsId
        data.goodsIds = this.modalGoodsId
      } else {
        data.goodsId = this.modalGoodsId[0]
      }
      http({
        instance: this,
        type: 'post',
        url,
        data,
        hasLoading: true,
        success: () => {
          this.$success(this.$t('发布成功'))
          this.publishVisible = false
          this.tableData.forEach((item) => {
            if (this.modalGoodsId.includes(item.goodsId)) {
              item.publishable = false
            }
          })
        },
      })
    },
    /**
     * 单元格合并处理
     * @param text 当前单元格的值
     * @param data 当前分页所有数据
     * @param key 当前列的dataIndex
     * @param index 当前数据所在下标
     * @returns {number} 待合并单元格数量
     */
    mergeCells(text, data, key, index) {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key]) {
        return 0
      }
      let rowSpan = 1
      // 判断下一行是否相等
      for (let i = index + 1; i < data.length; i++) {
        if (text !== data[i][key]) {
          break
        }
        rowSpan++
      }
      return rowSpan
    },
    handleChange(info) {
      const status = info.file.status
      if (status === 'done') {
        this.$success(info.file.name + this.$t('文件上传成功'))
        this.visible = false
      } else if (status === 'error') {
        this.$error(`${info.file.name}: ${info.file?.response?.msg}`)
        this.visible = false
      }
    },
    uploadIngredients(record) {
      http({
        url: api.getSkuPropertiesDetail,
        data: {
          skuId: record.skuId,
        },
        success: (res) => {
          this.currentSku = { properties: res.result, skuId: record.skuId, skuCode: record.skuCode }
          this.$nextTick(() => {
            this.$refs.propertiesModal.open()
          })
        },
      })
    },
    downloadIngredients(record) {
      this.currentSku = {
        properties: record.properties || {},
        skuId: record.skuId,
        skuCode: record.skuCode,
      }
      this.$refs.printinfo.open()
    },
    needProcess(record) {
      return ['FRESHLY_MADE_AND_SOLD', 'STOCK_UP_FOR_SALE'].includes(record.saleType)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';

.btnBgc {
  background-color: $primary-color;
  color: $white;
}

.batchBgc {
  color: $primary-color;
  border-color: $primary-color;
}

.action-list {
  display: flex;
}

.flex-btns {
  &.flex {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
  }

  .t-btn {
    cursor: pointer;
    margin-right: 10px;
    color: $primary-color;
    white-space: nowrap;
  }
}

.mt10 {
  margin-top: 10px;
  display: block;
}

// ::v-deep .ant-modal {
//   width: 840px !important;
// }
// ::v-deep .ant-modal-content {
//   width: 840px !important;
// }
// ::v-deep .ant-modal-body {
//   max-height: 550px;
//   overflow-y: auto;
// }
</style>
