<template>
  <div class="flex-ct flex-wrap gap-5">
    <img
      v-for="(url, index) in urls"
      :key="index"
      :src="url"
      @click="showPreview(url)"
      :style="imageStyle"
    />
    <a-modal v-model="visible" :footer="null" :width="800">
      <div class="preview-container no-select">
        <div
          class="arrow left"
          @click="prevImage"
          v-show="urls.length > 1"
          :class="{ disabled: currentIndex === 0 }"
        >
          <a-icon type="left" />
        </div>
        <img :src="currentUrl" style="width: 100%" />
        <div
          class="arrow right"
          @click="nextImage"
          v-show="urls.length > 1"
          :class="{ disabled: currentIndex === urls.length - 1 }"
        >
          <a-icon type="right" />
        </div>
        <div class="image-counter">{{ currentIndex + 1 }} / {{ urls.length }}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'ImagePreview',
  props: {
    urls: {
      type: Array,
      required: true,
    },
    imgStyle: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,
      currentUrl: '',
      currentIndex: 0,
    }
  },
  computed: {
    imageStyle() {
      return 'cursor: pointer;' + (this.imgStyle ? this.imgStyle : 'width: 100px; height: 100px')
    },
  },
  methods: {
    showPreview(url) {
      this.currentUrl = url
      this.currentIndex = this.urls.indexOf(url)
      this.visible = true
    },
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--
        this.currentUrl = this.urls[this.currentIndex]
      }
    },
    nextImage() {
      if (this.currentIndex < this.urls.length - 1) {
        this.currentIndex++
        this.currentUrl = this.urls[this.currentIndex]
      }
    },
  },
}
</script>

<style scoped>
.preview-container {
  position: relative;
  display: flex;
  align-items: center;
}

.arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  border-radius: 50%;
  transition: background 0.3s;
}

.arrow:hover {
  background: rgba(0, 0, 0, 0.5);
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.image-counter {
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
  color: #666;
}

.arrow.disabled {
  background: rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
}

.arrow.disabled:hover {
  background: rgba(0, 0, 0, 0.1);
}
</style>
